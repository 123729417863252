<template>
    <div class="login-wrapper">
        <div class="left">
            <div class="left-bg">
                <div class="left-bg-logo">
                </div>
                <div class="left-bg-body">
                    <div class="title">{{$t('login.professionalAppletDesignTool')}}</div>
                    <div class="description">{{$t('login.professionalAppletDesignToolDescription')}}</div>
                    <div class="other">{{$t('login.professionalAppletDesignToolOther')}}</div>
                    <div class="platform">
                        <div class="platform-item douyin"></div>
                        <div class="platform-item baidu"></div>
                        <div class="platform-item jingdong"></div>
                        <div class="platform-item kuaishou"></div>
                        <div class="platform-item qq"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import Language from "../../components/login/Language";
    import CommonLogin from "../../utils/CommonLogin";
    export default {
        name: "login",
        components: {Language},
        mounted() {

        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index";
    @input-width: 400px;
    .login-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .left{
            height: 100%;
            width: 400px;
            background-image: url("../../assets/edit-imgs/login/loginbg.png");
            position: relative;
            background-size: cover;
            background-position: 50%;

            .left-bg {
                width: 100%;
                height: 100%;
                position: relative;

                .left-bg-logo {
                    width: 98px;
                    height: 24px;
                    background-image: url("../../assets/edit-imgs/login/logo.png");
                    background-size: 100%;
                    position: absolute;
                    top: 28px;
                    left: 40px;
                }

                .left-bg-body {
                    width: 258px;
                    height: 250px;
                    position: absolute;
                    top: 35%;
                    left: 17%;
                    color: white;

                    .title {
                        font-weight: bold;
                        font-size: 18px;
                    }

                    .description {
                        font-size: 14px;
                        margin-top: 17px;
                        margin-bottom: 51px;
                        color: rgba(255, 255, 255, 0.85);
                    }

                    .other {
                        font-size: 12px;
                        margin-bottom: 12px;
                        color: rgba(255, 255, 255, 0.8);
                        line-height: 26px;
                    }

                    .platform {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        .platform-item {
                            width: 32px;
                            height: 32px;
                            margin-right: 16px;
                        }

                        .douyin {
                            background-image: url("../../assets/edit-imgs/login/douyin.png");
                            background-size: 100%;
                        }
                        .baidu {
                            background-image: url("../../assets/edit-imgs/login/baidu.png");
                            background-size: 100%;
                        }
                        .jingdong {
                            background-image: url("../../assets/edit-imgs/login/jingdong.png");
                            background-size: 100%;
                        }
                        .kuaishou {
                            background-image: url("../../assets/edit-imgs/login/kuaishou.png");
                            background-size: 100%;
                        }
                        .qq {
                            background-image: url("../../assets/edit-imgs/login/qq.png");
                            background-size: 100%;
                        }
                    }
                }
            }
        }
        .right{
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

    }
</style>
